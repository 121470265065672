import api from 'services/api';
export function addNotificationForUser(objArgs) {
  if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('type') || !objArgs.hasOwnProperty('notificationTime')) {
    throw new Error('missing required parameters');
  }
  return api.post('user/notifications', JSON.stringify(objArgs));
}

export function addNotificationConfiguration(objArgs) {
  if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('type') || !objArgs.hasOwnProperty('details')) {
    throw new Error('missing required parameters');
  }
  return api.put('user/notifications/config/' + objArgs.type, JSON.stringify({ details: objArgs.details }));
}

export function updateIeltsUserProfile(objArgs) {
  if (typeof objArgs !== 'object' || Object.keys(objArgs).length === 0) {
    throw new Error('missing required parameters');
  }
  return api.put('user/profile', JSON.stringify(objArgs));
}

export function updateSelfSettings(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.put('user/settings', JSON.stringify(objArgs));
}

export function getSelfPublic(objArgs) {
  if (typeof objArgs !== 'object' || !objArgs.hasOwnProperty('phone')) {
    throw new Error('missing required parameters');
  }
  return api.post(`public/user`, JSON.stringify(objArgs));
}

export function getSelfDetails(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(`user/details`);
}

export function getSelfActivity(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(
    `user/activity${Object.keys(objArgs).length > 0 ? '?' : ''}${Object.keys(objArgs)
      .map(el => `${el}=${objArgs[el]}`)
      .join('&')}`
  );
}

export function deleteSelf(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.post(`user/delete`, JSON.stringify(objArgs));
}
// export function getSelfActivityAnalytics(objArgs) {
// 	if (typeof objArgs !== 'object') {
// 		throw new Error('missing required parameters')
// 	}
// 	return api.get(
// 		`user/activity/analytics${
// 			Object.keys(objArgs).length > 0 ? '?' : ''
// 		}${Object.keys(objArgs)
// 			.map((el) => `${el}=${objArgs[el]}`)
// 			.join('&')}`
// 	)
// }

export function getSelfActivityAnalytics(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(`user/activity/analytics`);
}

export function getSelfWritingActivityAnalytics() {
  return api.get(`user/activity/writing_analytics`);
}

export function getSelfActivityTrend(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(
    `user/activity/trend${Object.keys(objArgs).length > 0 ? '?' : ''}${Object.keys(objArgs)
      .map(el => `${el}=${objArgs[el]}`)
      .join('&')}`
  );
}

export function getselfActivitySummary(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(`user/activity/summary`);
}

//objArgs = { contentId = "", contentCategory = "", isWatched = true/false, isLiked = true/false, isVisited = true/false }
export function updateContentTracker(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.post(`user/content_tracker`, JSON.stringify(objArgs));
}

export function getLikesVideo(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(`user/likes`);
}

//objArgs = { review: 'some text', rating: 10 }
export function putUserReview(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.put(`user/review`, JSON.stringify(objArgs));
}

//chart_type can be one of ('speaking', 'reading', 'listening', 'writing', 'overall')
export function getLeaderboardList(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(`/public/leaderboard/` + objArgs.chart_type);
}

export function getLeaderboardCurrentUser(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.get(`/user/leaderboard`);
}

// SOP
export function getUserSop(endpoint, objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.post(`/user/` + endpoint, JSON.stringify(objArgs));
}

// get All sops
export function getAllUserSops() {
  return api.get(`/user/aigenerator`);
}

export function getUserSops(type) {
  return api.get(`/user/aigenerator/` + type);
}

export function getUserSopById(type_id) {
  return api.get(`/user/aigenerator/byid/` + type_id);
}

export function getPlagScore(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.post(`/user/plagiarism`, JSON.stringify(objArgs));
}

export function trackEvent(objArgs) {
  if (typeof objArgs !== 'object') {
    throw new Error('missing required parameters');
  }
  return api.post(`/user/track`, JSON.stringify(objArgs));
}