import { getShortlisterById } from 'api/shortlisterApi';
import { getAllShortlisters } from 'api/shortlisterApi';
import { getAllCoursesFromShortlistUni } from 'api/shortlisterApi';
import { getWishList } from 'api/shortlisterApi';

// all universities

export const LOADING_SHORTLISTERS_START = 'LOADING_SHORTLISTERS_START';
export const LOADING_SHORTLISTERS_SUCCESS = 'LOADING_SHORTLISTERS_SUCCESS';
export const LOADING_SHORTLISTERS_FAILED = 'LOADING_SHORTLISTERS_FAILED';

export const ADDED_FOR_SHORTLISTERS = 'ADDED_FOR_SHORTLISTERS';
export const LOADING_HISTORY_START = 'LOADING_HISTORY_START';
export const LOADING_HISTORY_ERROR = 'LOADING_HISTORY_ERROR'
export const CLEAR_SHORTLISTERS = 'CLEAR_SHORTLISTERS';

export const ADDED_NEW_HISTORY = 'ADDED_NEW_HISTORY';
export const ADD_TO_LIST = 'ADD_TO_LIST';

export const loadingHistoryStartAction = () => {
  return {
    type: LOADING_HISTORY_START,
  };
};

export const loadingHistoryError = () => {
  return {
    type: LOADING_HISTORY_ERROR,
  };
};

export const addedForMyShortlist = shortlister => {
  return {
    type: ADDED_FOR_SHORTLISTERS,
    payload: shortlister,
  };
};

export const clearShortlistersAction = () => ({
  type: CLEAR_SHORTLISTERS,
});

export const loadingShortlistersStartAction = () => {
  return {
    type: LOADING_SHORTLISTERS_START,
  };
};

export const loadingShortlistersSuccessAction = shortlisters => {
  return {
    type: LOADING_SHORTLISTERS_SUCCESS,
    payload: shortlisters,
  };
};

export const createdNewHistoryItem = historyItem => {
  return {
    type: ADDED_NEW_HISTORY,
    payload: historyItem,
  };
};

export const addStatusToList = item => {
  return {
    type: ADD_TO_LIST,
    payload: item,
  };
};

export const loadingShortlistersFailedAction = e => {
  return {
    type: LOADING_SHORTLISTERS_FAILED,
    payload: e,
  };
};

export const getShortlister =
  (id, page = 1) =>
  async (dispatch, getState) => {
    dispatch(loadingShortlistersStartAction());

    try {
      const [response, res] = await Promise.all([getWishList(), getShortlisterById(id, page)]);

      console.log('res', res.data);

      const wishlistArray = combineArrays(response.data);

      const shortlistersWithData = await Promise.all(
        res.data.universities.map(async el => {
          const courses = await getAllCoursesFromShortlistUni(id, el.id);
          const uniqueCoursesSet = new Set();
          const uniqueCoursesData = [];

          courses.data.forEach(course => {
            if (!uniqueCoursesSet.has(course.course_id)) {
              uniqueCoursesSet.add(course.course_id);
              uniqueCoursesData.push(course);
            }
          });

          const course = uniqueCoursesData[0];
          let addToList = [];
          try {
            if (uniqueCoursesData && wishlistArray) {
              addToList = uniqueCoursesData.some(course =>
                wishlistArray.some(wish => wish.course_id === course.course_id)
              );
              // addToList = wishlistArray.some(wish => wish.course_id === course.course_id);
            }
          } catch (error) {}

          return {
            ...el,
            courses: course,
            addToList,
            coursesList: uniqueCoursesData,
          };
        })
      );

      const dreamList = [];
      const targetList = [];
      const safeList = [];

      shortlistersWithData.forEach(el => {
        switch (el.bucket) {
          case 'dream':
            dreamList.push(el);
            break;
          case 'target':
            targetList.push(el);
            break;
          case 'safe':
            safeList.push(el);
            break;
          default:
            console.warn(`Unknown bucket: ${el.bucket}`);
        }
      });

      const deduplicateById = (existingList, newList) => {
        const combinedList = [...existingList, ...newList];
        const uniqueMap = new Map(combinedList.map(uni => [uni.id, uni])); // Ensures uniqueness by id
        return Array.from(uniqueMap.values());
      };

      const data = getState().shortlisters.shortlisters || {
        dreamList: [],
        targetList: [],
        safeList: [],
      };

      const updatedDreamList = deduplicateById(data.dreamList, dreamList);
      const updatedTargetList = deduplicateById(data.targetList, targetList);
      const updatedSafeList = deduplicateById(data.safeList, safeList);

      dispatch(
        loadingShortlistersSuccessAction({
          id,
          dreamList: updatedDreamList,
          targetList: updatedTargetList,
          safeList: updatedSafeList,
          shortlistLength: updatedDreamList.length + updatedTargetList.length + updatedSafeList.length,
          total_pages: res.data.pagination.total_pages,
          current_page: res.data.pagination.current_page,
        })
      );
    } catch (error) {
      dispatch(loadingShortlistersFailedAction(error));
    }
  };

export const getAllHistory = () => async dispatch => {
  dispatch(loadingHistoryStartAction());
  try {
    const res = await getAllShortlisters();
    const arrayOfIds = res.data.map((obj, index) => ({
      id: obj.id,
      name: `Shortlist #${index + 1}`,
      query: obj.query,
      date: obj.date_created,
    }));

    dispatch(addedForMyShortlist(arrayOfIds));
  } catch (error) {
    dispatch(loadingHistoryError());
  }
};

const combineArrays = ({ dream = [], safe = [], target = [] }) => {
  return [...dream, ...safe, ...target];
};
