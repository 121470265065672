import * as Actions from '../actions/shortlistersAction';

const initialState = {
  shortlisters: null,
  currentPage: null,
  totalPages: null,
  myShortListers: [],
  status: 'idle',
  isLoadedShortlist: null,
};

const ShortlistersReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_SHORTLISTERS_START: {
      return {
        ...state,
        status: 'idle',
      };
    }

    case Actions.LOADING_HISTORY_START: {
      return {
        ...state,
        isLoadedShortlist: false,
      }
    }

    case Actions.LOADING_HISTORY_ERROR: {
      return {
        ...state,
        isLoadedShortlist: null,
      }
    }

    case Actions.LOADING_SHORTLISTERS_SUCCESS: {
      return {
        ...state,
        shortlisters: action.payload,
        currentPage: action.payload.current_page,
        totalPages: action.payload.total_pages,
        status: 'success',
      };
    }

    case Actions.LOADING_SHORTLISTERS_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }

    case Actions.ADDED_FOR_SHORTLISTERS: {
      return {
        ...state,
        myShortListers: [...state.myShortListers, ...action.payload],
        isLoadedShortlist: true,
      };
    }

    case Actions.ADDED_NEW_HISTORY: {
      return {
        ...state,
        myShortListers: [...state.myShortListers, action.payload],
        isLoadedShortlist: true,
      };
    }

    case Actions.CLEAR_SHORTLISTERS: {
      return {
        ...state,
        shortlisters: null,
        currentPage: null,
        totalPages: null,
        status: 'idle',
      };
    }

    case Actions.ADD_TO_LIST: {
      const updateList = list => {
        return list.map(item => {
          const isCourseInList = item.coursesList && item.coursesList.some(course => course.course_id === action.payload);
          if (isCourseInList) {
            return { ...item, addToList: true };
          }
          return item;
        });
      };

      return {
        ...state,
        shortlisters: {
          ...state.shortlisters,
          dreamList: updateList(state.shortlisters.dreamList),
          targetList: updateList(state.shortlisters.targetList),
          safeList: updateList(state.shortlisters.safeList),
        },
      };
    }

    default:
      return state;
  }
};

export default ShortlistersReducer;
