import {
  getAutoFillShortlisterUniversity,
  getAutoFillShortlisterCourses,
  getAutoFillUniversity,
} from 'api/autoFillApi';
import { v4 as uuidv4 } from 'uuid';

export const LOADING_AUTOFILL_START = 'LOADING_AUTOFILL_START';
export const LOADING_AUTOFILL_SUCCESS = 'LOADING_AUTOFILL_SUCCESS';
export const LOADING_AUTOFILL_FAILED = 'LOADING_AUTOFILL_FAILED';
export const LOADING_AUTOFILL_SHORTLISTER_START = 'LOADING_AUTOFILL_SHORTLISTER_START';
export const LOADING_AUTOFILL_SHORTLISTER_SUCCESS = 'LOADING_AUTOFILL_SHORTLISTER_SUCCESS';
export const LOADING_AUTOFILL_COURSES_START = 'LOADING_AUTOFILL_COURSES_START';
export const LOADING_AUTOFILL_POST_SUCCESS = 'LOADING_AUTOFILL_POST_SUCCESS';
export const LOADING_AUTOFILL_UNDER_SUCCESS = 'LOADING_AUTOFILL_UNDER_SUCCESS';

export const loadingAutoFillStartAction = () => {
  return {
    type: LOADING_AUTOFILL_START,
  };
};

export const loadingAutoFillCoursesStartAction = () => {
  return {
    type: LOADING_AUTOFILL_COURSES_START,
  };
};

export const loadingAutoFillShortlisterStartAction = () => {
  return {
    type: LOADING_AUTOFILL_SHORTLISTER_START,
  };
};

export const loadingAutoFillSuccessAction = autoFill => {
  return {
    type: LOADING_AUTOFILL_SUCCESS,
    payload: autoFill,
  };
};

export const loadingAutoFillFailedAction = e => {
  return {
    type: LOADING_AUTOFILL_FAILED,
    payload: e,
  };
};

export const loadingAutoFillShortlisterAction = autoFill => {
  return {
    type: LOADING_AUTOFILL_SHORTLISTER_SUCCESS,
    payload: autoFill,
  };
};

export const loadingAutoFillPostAction = autoFillPost => {
  return {
    type: LOADING_AUTOFILL_POST_SUCCESS,
    payload: autoFillPost,
  };
};

export const loadingAutoFillUnderAction = autoFillUnder => {
  return {
    type: LOADING_AUTOFILL_UNDER_SUCCESS,
    payload: autoFillUnder,
  };
};
export const getAllAutoFillUniversity = () => async dispatch => {
  try {
    dispatch(loadingAutoFillStartAction());
    const res = await getAutoFillUniversity();
    dispatch(loadingAutoFillSuccessAction(res.data));
  } catch (error) {
    dispatch(loadingAutoFillFailedAction(error));
  }
};

export const getAutoFillShortlister = () => async dispatch => {
  try {
    dispatch(loadingAutoFillShortlisterStartAction());

    const res = await getAutoFillShortlisterUniversity();

    const newArray = res.data.reduce((acc, item) => {
      acc.push({ id: item.id, name: item.title });
      return acc;
    }, []);

    dispatch(loadingAutoFillShortlisterAction(newArray));
  } catch (error) {
    dispatch(loadingAutoFillFailedAction(error));
  }
};

export const getAutoFillCourses = () => async dispatch => {
  try {
    dispatch(loadingAutoFillCoursesStartAction());
    const res = await getAutoFillShortlisterCourses();

    const newArrayOfCourses = res.data[0].reduce(
      (acc, item) => {
        const degree = item.degree;
        if (degree === 'Postgraduate' || degree === 'Undergraduate') {
          acc[degree].push({ id: uuidv4(), name: item.course_name, degree: degree, value: item.course_name });
        }
        return acc;
      },
      { Postgraduate: [], Undergraduate: [] }
    );

    const postgraduateCourses = newArrayOfCourses.Postgraduate;
    const undergraduateCourses = newArrayOfCourses.Undergraduate;
    dispatch(loadingAutoFillPostAction(postgraduateCourses));
    dispatch(loadingAutoFillUnderAction(undergraduateCourses));
  } catch (error) {}
};
