import * as Actions from '../actions/webinarsAction';

const initialState = {
  webinars: [],
  status: 'idle',
};

const WebinarsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_WEBINARS_START: {
      return {
        ...state,
        status: 'start',
      };
    }

    case Actions.LOADING_WEBINARS_SUCCESS: {
      return {
        ...state,
        webinars: action.payload,
        status: 'success',
      };
    }
    case Actions.LOADING_WEBINARS_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }

    default:
      return state;
  }
};

export default WebinarsReducer;
