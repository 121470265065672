import * as Actions from '../actions/counselorAction';

const initialState = {
  counselors: [],
  counselors_admin: [],

  status: 'idle',
};

const CounselorReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOADING_COUNSELOR_START: {
      return {
        ...state,
        status: 'start',
      };
    }

    case Actions.LOADING_COUNSELOR_SUCCESS: {
      return {
        ...state,
        counselors: action.payload,
        status: 'success',
      };
    }
    case Actions.LOADING_COUNSELOR_FAILED: {
      return {
        ...state,
        status: 'error',
      };
    }

    case Actions.LOADING_COUNSELOR_ADMIN_SUCCESS: {
      return {
        ...state,
        counselors_admin: action.payload,
        status: 'success',
      };
    }

    case Actions.CHANGE_BOOKING_STATUS: {
      return {
        ...state,
        counselors_admin: state.counselors_admin.map(item => {
          if (item.id === action.payload) {
            return {
              ...item,
              isBooked: 1,
            };
          }
          return item;
        }),
      };
    }

    default:
      return state;
  }
};

export default CounselorReducer;
