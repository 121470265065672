import React from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as PremiumWhiteIcon } from '../../../assets/kandor-img/premium-icon-white.svg';
import { useHistory } from 'react-router-dom';
import './UnlockBtn.scss';

const UnlockBtn = ({ claz = '', onClick = () => {}, type = 'button', isBig = false, isInline = false }) => {
  const history = useHistory();
  const clickHandler = e => {
    onClick(e);
    history.push('/admin/referral');
  };
  return (
    <Button
      className={`unlock-btn ${claz ? claz : ''} ${isBig ? 'unlock-btn--big' : ''}`}
      color="info"
      onClick={clickHandler}
      type={type}>
      <span className="unlock-btn__icon">
        {' '}
        <PremiumWhiteIcon />{' '}
      </span>
      <span className="unlock-btn__text">Refer & Upgrade</span>
      {/* {isInline ? (
				'Refer & Upgrade'
			): (
				<span className="unlock-btn__text">Refer & Upgrade</span>
			)} */}
    </Button>
  );
};

export default UnlockBtn;
